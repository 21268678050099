.container{
    position: relative;
    z-index: 2;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .circle{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70vh;
        height: 70vh;
        border-radius: 100%;
        background: radial-gradient(circle at top, #211960, #8C2367);  
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 30px;
        z-index: -1;
    }
    .contact_section{
        z-index: 4;
        max-width: 850px;
        position: relative;
        margin: 0 auto;
        text-align: center;
        .contact_flex{
            margin: 2rem 0 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            .flex{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                .canvas{
                    width: 130px !important;
                    height: 100%;
                    >div{
                    width: 100px !important;
                    min-height: 100px;
                    cursor: grab;
                    }
                }
                a{
                    width: 100%;
                    color: rgba(245, 245, 245, 0.7);
                    &:hover{
                        text-decoration: underline;
                        color: white;
                    }
                    .text_container{
                        text-align: left;
                        h5{
                            color: rgba(245, 245, 245, 0.7);
                            &:hover{
                                color: white;
                            }
                        }  

                    }
                }
          
            }  
        }
    }

}

@media (min-width: 767px) { 
    .container{
        height: 100vh;
        .circle{
            bottom: -10vw;
            width: 60vw;
            height: 60vw;
            max-width: 1000px;
            max-height: 1000px;
            min-width: 650px;
            min-height: 650px;
        }
        .contact_section{
            .contact_flex{
                margin: 8rem 0;
                flex-direction: row;
                .flex{
                    flex-direction: column;
                    .canvas{
                        width: 100%;
                        height: 100%;
                        >div{
                        min-width: 150px;
                        min-height: 150px;
                        cursor: grab;
                        }
                    }
                    h5{
                        text-align: center;
                    }
                    .text_container{
                        text-align: center;
                    }
                }
            }
        }
    }
 }