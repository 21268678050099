.container{
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    position: relative;
    >div {
        width: 100%;
    }
    .text_box{
        margin-top: 2rem;
        width: 100%;
        z-index: 3;
        h2{
            max-width: 450px;
            width: 100%;
        }
        .sub_text_box{
            margin: 6rem auto 2rem auto;
            max-width: 250px;
            h5{
                margin-bottom: 1rem;
            }
        }
    }
    .imgs_box{
        .canvas{
            width: 100%;
            height: 100%;
            >div{
            min-width: 50vw;
            min-height: 700px;
            cursor: grab;
            }
        }
    }
}

@media (min-width: 767px) { 
    .container{
        flex-direction: row;
        >div {
            width: 50vw;
        }
        .text_box{
            margin-top: 0;
            h2{
                width: 50vw;
            }
        }
        .imgs_box{
            .canvas{
                >div{
                min-height: 90vh;
                }
            }
        }
    }
 }