.container{
    position: relative;
    top: 0;
    z-index: 99;
    .header{
        position: fixed;
        right: 4vw;
        top: 1rem;
        color: white;
        z-index: 9999999;

    }
    .nav{
        z-index: 999999;
        border-radius: 0 0 0 100%;
        background: radial-gradient(circle at top right, rgba(33, 25, 96, 0.8), #8C2367);
        backdrop-filter: blur(5px);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 30px;
        width: 450px;
        max-width: 90vw;
        min-height: 350px;
        position: fixed;
        right: 0;
        margin: 0 auto;
        padding: 5rem 0;
        top: -80vh;
        transition: top 1s ease;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .tab{
            white-space: nowrap;
            text-align: right;
            cursor: pointer;
            color: rgba(245, 245, 245, 0.7);
            overflow: hidden;
            padding-right: 5vw;
            transition-property: opacity;
            transition-duration: 1s;
            transition-timing-function: ease-in;
            margin-bottom: 1rem;
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
            &:hover{
                color: white;
            }
        }
    }
}


@media (min-width: 820px) {

}