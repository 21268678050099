.language_container{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    div{
        button{
            background-color: transparent;
            border: none;
            color: rgb(245, 245, 245, 0.7);
            cursor: pointer;
            &:hover{
                color: white;
            }
            &:focus{
                color: white;
            }
        }  
    }
}


