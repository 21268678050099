*{
    box-sizing: border-box;
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
html{
	background-color: #8C2367;
}
body {
	background: radial-gradient(circle at center, #211960, #8C2367, #F2306D);
	background-size: 400% 400%;
	animation: gradient 25s ease-out infinite;
	height: auto;
	color: white;
}

a{
	text-decoration: none;
	color: white;
}
 
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


h1{

}
h2{
	font-size: 36px;
	font-weight: 700;
	line-height: 120%;
	letter-spacing: 0.02em;
}
h3{
	font-size: 36px;
	font-weight: 100;
	line-height: 120%;
	font-style: italic;
}
h4{
	font-size: 24px;
	font-weight: 400;
	line-height: 120%;
}
h5{
	font-size: 24px;
	font-weight: 300;
	line-height: 120%;
	color: rgba(245, 245, 245, 0.7);
}
h6{
	font-size: 18px;
	font-weight: 300;
	font-weight: 100;
	line-height: 120%;
	font-style: italic;
}
p{
	font-size: 16px;
	font-weight: 300;
	line-height: 120%;
}

@media (min-width: 767px) { 
	h1{

	}
	h2{
		font-size: 40px;
		font-weight: 700;
		line-height: 48px;
		letter-spacing: 0.02em;
	}
	h3{
		font-size: 48px;
		font-weight: 100;
		line-height: 52px;
		font-style: italic;
	}
	h4{
		font-size: 36px;
		font-weight: 300;
		line-height: 46px;
	}
	h5{
		font-size: 24px;
		font-weight: 300;
		line-height: 29px;
		color: rgba(245, 245, 245, 0.7);
	}
	h6{
		font-size: 18px;
		font-weight: 300;
		font-weight: 100;
		line-height: 24px;
		font-style: italic;
	}
	p{
		font-size: 16px;
		font-weight: 300;
		line-height: 19px;
	}
}