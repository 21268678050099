// .container{
//     min-height: 100vh;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     h1{
//         font-size: 128px;
//     }
//     span{
//         color: rgba(191, 78, 48);
//     }
// }

.container{
    min-height: 100vh;
    width: 100%;
    position: relative;
    .content{
        z-index: 9999;
        h1{
            font-size: 36px;
        }
        h5{
            font-size: 14px;
        }
        .creative{
            display: flex;
            mix-blend-mode: difference;
            h1{
                background: linear-gradient(to bottom, #ffffff 59%, transparent 41%);

                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        .second_line_container{
            display: flex;
            position: relative;
            top: -36px;
            left: -1px;
        }
        .visual{
            display: flex;
            h1{
                position: relative;
                top: 0;
                background: linear-gradient(to bottom, transparent 55%, #BF4E30 45%);
                background: linear-gradient(to bottom, transparent 55%, white 45%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                transition: all .3s ease-out;
            }

        }
        .developer{
            width: max-content;
            margin-left: 4px;
            h5{
                position: relative;
                bottom: 5px;
                color: rgb(231, 231, 231);
                font-weight: 400;
                line-height: 30px;
            } 
        }
    }
}

@media (min-width: 767px) { 
    .container{
        .content{
            margin-top: 1rem;
            .second_line_container{
                top: -48px;
                left: -2px;
            }
            h1{
                font-size: 48px;
            }
            .developer{
                h5{
                    bottom: 2px;
                    font-size: 20px;
                } 
            }
        }
    }
 }