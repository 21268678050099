.container{
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    ul{
        width: 100%;
        padding: 0;
    }
    li{
        list-style-type: none;
        >a{ 
            padding: 2rem 0 1rem 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            border-bottom: 1px solid white;
            cursor: pointer;
            &:hover{
                transition: all .5s ease-in-out;
    
            }
            &:hover >div, &:hover >p{
                transition: all .5s ease-in-out;
            }
            >div{
                width: 100%;
                height: auto;
            }
            >p{
                max-width: 250px;
                text-align: right;
            }
        }
  
    }
}