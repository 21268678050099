.container{
    position: relative;
    margin: 0 auto;
    min-height: 100vh;
    padding: 2rem 0;
    .go_back{
        position: fixed;
        z-index: 100;
        top: 2rem;
        left: 2rem;
        color: white;
        cursor: pointer;
        svg{
            width: 20px;
        }
    }
    .text_container{
        position: fixed;
        top: -500px;
        left: -460px;
        width: 900px;
        height: 900px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: radial-gradient(circle at top right, rgba(33, 25, 96, 0.8), #8C2367);
        backdrop-filter: blur(5px);
        border-radius: 100%;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 30px;
        z-index: 9;
        padding: 5rem 5vw 5rem 5vw;
        cursor: pointer;
        .tab{
            position: absolute;
            bottom: 200px;
            right: 60px;
            transform: rotate(-55deg);
        }
        >section{
            width: 300px;
            margin: 0 auto;
            position: absolute;
            bottom: 4rem;
            right: 100px;
            margin-top: 1rem;
            >div{
                max-width: 50%;
                h5{
                    font-size: 18px;
                    margin-top: 1rem;
                }
                h4{
                    font-size: 16px;
                    line-height: 18px;
                }
            }          
        }   
        a{
            text-decoration: underline;
        }
   
    }
    .gallery_container{
        >div{
            box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 30px;
            overflow: scroll;
            position: relative;
            left: -5vw;
            margin: 4rem auto;
            width: 100vw;
            max-height: 800px;
            object-fit: cover;
            img{
                width: 100vw;
            }
        }
    }
}

.my_masonry_grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    padding-top: 70px;
  }
  .my_masonry_grid_column {
    padding: 0 1vw;
    background-clip: padding-box;
  }
  /* Style your items */
  .my_masonry_grid_column > div {
    margin-bottom: 1vw;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 30px;
  }
  .my_masonry_grid_column > div > img {
    width: 100%;
    border-radius: 8px;
    position: relative;
    transition: all 0.3s ease;
  }

@media (min-width: 820px) {
    .container{
        .text_container{
            top: -400px;
            >section{
                >div{
                    h5{
                        font-size: 18px;
                        margin-top: 1rem;
                    }
                    h4{
                        font-size: 20px;
                        line-height: 28px;
                    }
                }          
            } 
        }
        .gallery_container{
            margin: 0 0 8rem 0;
            >div{
                margin-top: 3rem;
                height: auto;
                img{
                    object-position: 0;
                    width: 100%;
                }
            }
        }
    }
}